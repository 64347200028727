import * as React from "react";
import {Box} from "@chakra-ui/react";

export default function Card(props) {
    const { backgroundColor, children, marginBottom, ...rest } = props;

    return (
        <Box
            backgroundColor={backgroundColor}
            rounded="5vh"
            width="100%"
            padding={8}
            marginTop={16}
            marginBottom={marginBottom ?? 0}
            {...rest}
        >
            {children}
        </Box>
    );
}