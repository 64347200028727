import * as React from "react";
import {useBreakpointValue, Grid, VStack, GridItem} from "@chakra-ui/react";

export function GridOrStack(props) {
    const useGrid = useBreakpointValue({ base: false, lg: true });

    if (useGrid) {
        return (
            <Grid
                templateColumns="repeat(3, 1fr)"
                gap={8}
                // height="100%"
                alignItems="center"
                paddingTop={props.marginTop ?? 16}
                paddingBottom={props.marginBottom ?? 0}
                minHeight="80vh"
            >
                {props.children}
            </Grid>
        );
    } else {
        let elements = props.children.slice();
        if (props.reverseOnStack) {
            elements = elements.reverse();
        }

        return (
            <VStack
                spacing={8}
                alignItems="center"
                textAlign="center"
                marginTop={props.marginTop ?? 16}
                marginBottom={props.marginBottom ?? 0}
            >
                {elements}
            </VStack>
        );
    }
}

export function GridOrStackItem(props) {
    const useGrid = useBreakpointValue({ base: false, lg: true });

    if (useGrid) {
        const {rowSpan, colSpan} = props;

        return (
            <GridItem
                rowSpan={rowSpan}
                colSpan={colSpan}
            >
                {props.children}
            </GridItem>
        );
    } else {
        return props.children;
    }
}