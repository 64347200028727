import * as React from "react";
import {Center, Image} from "@chakra-ui/react";

export default function AppImage(props) {
    return (
            <Image
                src={props.image}
                maxHeight={["60vh", "70vh", "80vh"]}
            />
    );
}