import * as React from "react";
import {
    Heading,
    Text,
    Stack,
    Box
} from "@chakra-ui/react";
import {GridOrStack, GridOrStackItem} from "./gridOrStack";
import AppImage from "./appImage";

export default function About(props) {
    const { title, body, body2, lhs, image } = props;

    const imageGridItem = (
        <GridOrStackItem
            rowSpan={1}
            colSpan={1}
        >
            <AppImage image={props.image} />
        </GridOrStackItem>
    );

    return (
        <GridOrStack
            reverseOnStack={lhs}
        >
            {lhs ? imageGridItem : null}
            <GridOrStackItem
                rowSpan={1}
                colSpan={2}
            >
                <Stack
                    align={["center", "center", "center", "flex-start"]}
                    spacing={8}
                >
                    <Heading
                        size="2xl"
                        fontWeight="bold"
                        color="brand.700"
                        textAlign={["center", "center", "center", "left"]}
                    >
                        {title}
                    </Heading>
                    <Box
                        height="2px"
                        width="30%"
                        backgroundColor="brand.500"
                    />
                    <Text
                        fontSize="xl"
                        color="black"
                        fontWeight="normal"
                        lineHeight={1.5}
                        textAlign={["center", "center", "center", "left"]}
                    >
                        {body}
                    </Text>
                    {body2 ?
                        <Text
                            fontSize="xl"
                            color="black"
                            fontWeight="normal"
                            lineHeight={1.5}
                            textAlign={["center", "center", "center", "left"]}
                        >
                            {body2}
                        </Text>
                        : null
                    }
                </Stack>
            </GridOrStackItem>
            {lhs ? null : imageGridItem}
        </GridOrStack>
    );
}