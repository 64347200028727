import * as React from "react";
import {Heading, Box} from "@chakra-ui/react";
import {freeText} from "../helper/text";

export default function Conclusion() {
    return (
        <>
            <Box
                marginTop={16}
                textAlign="center"
            >
                <Heading
                    display="inline"
                    textAlign="center"
                    size="xl"
                    color="black"
                    fontWeight="bold"
                    opacity="0.7"
                >
                    {freeText.part1}
                </Heading>
                <Heading
                    display="inline"
                    textAlign="center"
                    size="xl"
                    color="brand.700"
                    fontWeight="bold"
                >
                    {freeText.part2}
                </Heading>
            </Box>
            {/*<Box*/}
            {/*    marginTop={16}*/}
            {/*>*/}
            {/*    <Heading*/}
            {/*        as="a"*/}
            {/*        display="inline"*/}
            {/*        textAlign="center"*/}
            {/*        size="xl"*/}
            {/*        color="black"*/}
            {/*        fontWeight="bold"*/}
            {/*        opacity="0.7"*/}
            {/*        marginTop={16}*/}
            {/*        href={downloadLink}*/}
            {/*        textDecoration="underline"*/}
            {/*    >*/}
            {/*        Download today!*/}
            {/*    </Heading>*/}
            {/*</Box>*/}
        </>
    )
}