import * as React from "react";
import {Heading, Image, Stack, Link} from "@chakra-ui/react";
import homeImage from "../images/home.png";
import downloadBadge from "../images/downloadbadge.svg";
import {headerText} from "../helper/text";
import {downloadLink} from "../helper/links";
import {GridOrStack, GridOrStackItem} from "./gridOrStack";
import AppImage from "./appImage";
import DownloadButton from "./downloadButton";

export default function Header() {
    return (
        <GridOrStack
            reverseOnStack={true}
            marginBottom={16}
        >
            <GridOrStackItem
                rowSpan={1}
                colSpan={2}
            >
                <Stack
                    align={["center", "center", "center", "flex-start"]}
                    spacing={8}
                >
                    <Heading
                        as="h1"
                        size="3xl"
                        fontWeight="bold"
                        color="white"
                        textAlign={["center", "center", "center", "left"]}
                    >
                        {headerText.title1}<br />{headerText.title2}
                    </Heading>
                    <Heading
                        as="h2"
                        size="lg"
                        color="white"
                        opacity="0.8"
                        fontWeight="normal"
                        lineHeight={1.5}
                        textAlign={["center", "center", "center", "left"]}
                    >
                        {headerText.subtitle}
                    </Heading>
                    <DownloadButton />
                </Stack>
            </GridOrStackItem>
            <GridOrStackItem
                rowSpan={1}
                colSpan={1}
            >
                <AppImage image={homeImage} />
            </GridOrStackItem>
        </GridOrStack>
    );
}