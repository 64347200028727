import * as React from "react";
import {Icon, Heading, HStack, Text, VStack, Button} from "@chakra-ui/react";
import {AiFillGithub, AiFillLinkedin} from "react-icons/ai";
import {mySiteLink, myGitHubLink, myLinkedInLink} from "../helper/links";
import {IoGlobeOutline} from "react-icons/io5";
import {bioText} from "../helper/text";

export default function BioCardContent() {
    return (
        <VStack
            spacing={8}
            align="left"
        >
            <Heading
                size="xl"
                fontWeight="bold"
                textAlign="left"
            >
                {bioText.title}
            </Heading>
            <Text
                fontSize="lg"
                color="black"
                fontWeight="normal"
                lineHeight={1.5}
                textAlign="left"
            >
                {bioText.body}
            </Text>
            <HStack>
                <Button
                    as="a"
                    href={mySiteLink}
                    padding={4}
                    backgroundColor="brand.500"
                >
                    <Icon
                        as={IoGlobeOutline}
                        height="24px"
                        width="24px"
                        color="white"
                    />
                </Button>
                <Button
                    as="a"
                    href={myGitHubLink}
                    padding={4}
                    backgroundColor="brand.500"
                >
                    <Icon
                        as={AiFillGithub}
                        height="24px"
                        width="24px"
                        color="white"
                    />
                </Button>
                <Button
                    as="a"
                    href={myLinkedInLink}
                    padding={4}
                    backgroundColor="brand.500"
                >
                    <Icon
                        as={AiFillLinkedin}
                        height="24px"
                        width="24px"
                        color="white"
                    />
                </Button>
            </HStack>
        </VStack>
    );
}