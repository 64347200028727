import * as React from "react";
import {ChakraProvider} from "@chakra-ui/react"
import theme from "../styles/theme";
import {PageWrap} from "../components/wrap";
import Header from "../components/header";
import {Footer} from "../components/footer";
import About from "../components/about";
import breathingImage from "../images/breathing.png";
import widgetsImage from "../images/widgets.png";
import Card from "../components/card";
import BioCardContent from "../components/bioCardContent";
import {about1Text, about2Text, helmetText} from "../helper/text";
import Conclusion from "../components/conclusion";
import HelmetHeader from "../components/helmetHeader";
import AppClipCards from "../components/appClipCards";

export default function Index() {
    return (
        <>
            <HelmetHeader
                title={helmetText.title}
                blueTheme={true}
            />
            <ChakraProvider theme={theme}>
                <PageWrap
                    bgColor="brand.500"
                >
                    <Header/>
                </PageWrap>
                <PageWrap
                    bgColor="white"
                >
                    <About
                        title={about1Text.title}
                        body={about1Text.body}
                        body2={about1Text.body2}
                        lhs={true}
                        image={breathingImage}
                    />
                    {/*<AppClipCards />*/}
                    <About
                        title={about2Text.title}
                        body={about2Text.body}
                        body2={about2Text.body2}
                        lhs={false}
                        image={widgetsImage}
                    />
                    <Conclusion/>
                    <Card
                        backgroundColor="gray.100"
                        marginBottom={16}
                    >
                        <BioCardContent/>
                    </Card>
                </PageWrap>
                <Footer/>
            </ChakraProvider>
        </>
    );
}