import {downloadLink} from "../helper/links";
import {Image, Link} from "@chakra-ui/react";
import downloadBadge from "../images/downloadbadge.svg";
import * as React from "react";

export default function DownloadButton(props) {
    return (
        <Link
            href={downloadLink}
            isExternal
            {...props}
        >
            <Image
                src={downloadBadge}
                height={["10vh", "8vh", "6vh"]}
            />
        </Link>
    );
}