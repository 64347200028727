import * as React from "react"
import {Center, Wrap, WrapItem, Text, VStack, Link} from "@chakra-ui/react";
import {homeLink, privacyLink, supportLink, termsLink, mySiteLink, downloadLink} from "../helper/links";

export function Footer() {
    return (
        <Center
            backgroundColor="brand.500"
            padding="24px"
            paddingTop="48px"
            paddingBottom="48px"
        >
            <VStack
                spacing="24px"
            >
                <Wrap
                    spacing="32px"
                    justify="center"
                >
                    <WrapItem>
                        <Link
                            href={homeLink}
                            color="white"
                        >
                            Home
                        </Link>
                    </WrapItem>
                    <WrapItem>
                        <Link
                            href={downloadLink}
                            color="white"
                            isExternal
                        >
                            Download
                        </Link>
                    </WrapItem>
                    <WrapItem>
                        <Link
                            href={mySiteLink}
                            color="white"
                            isExternal
                        >
                            About Me
                        </Link>
                    </WrapItem>
                    <WrapItem>
                        <Link
                            href={supportLink}
                            color="white"
                        >
                            Support
                        </Link>
                    </WrapItem>
                    <WrapItem>
                        <Link
                            href={termsLink}
                            color="white"
                        >
                            Terms
                        </Link>
                    </WrapItem>
                    <WrapItem>
                        <Link
                            href={privacyLink}
                            color="white"
                        >
                            Privacy
                        </Link>
                    </WrapItem>
                </Wrap>
                <Text
                    color="white"
                    align="center"
                >
                    iPhone, iOS and Siri are registered trademarks of Apple Inc., registered in the US and other countries.
                </Text>
                <Text
                    color="white"
                    align="center"
                >
                    Copyright 2021 Benjamin Robinson. All Rights Reserved.
                </Text>
            </VStack>
        </Center>
    );
}